function reloadImageForm(whatIsClicked){
  setTimeout(function (){
    if ($('#image_edit_form').is(':visible') == false){
      location.reload();
    }
  }, 1000);
}

function disableAssignButton(whatIsClicked, response){
  $('#js-assign_author-' + response.identifier).removeClass('js-open-modal').addClass('button--disabled');
}

function reloadPage(){
  location.reload();
}

function updateUserAvatar (data) {
  $('.js-my-avatar').attr('src', data.userAvatarPath)
  $('.preview_avatar').attr('src',data.thumbPath);
  $('#profile_thumb_image').attr('src', data.thumbPath)
}

function updateBlock(){
  const block = $('.js-update-block');
  fillContent(block);
}

function myNewQuestionUpdate(data){
  $('#my-questions-list-container').prepend(data.newQuestion);
}

function reloadContributionsCount(){
  const container = $('#contributions_count');

  if (container.length > 0){
    $.ajax({
      url: '/questions-and-answers/contributions',
      success: function (result){
        container.html(result);
      }
    });
  }
}

function reloadBasicInfoForm () {
  let basicInfoContainer = $('#basic_info_wrap');
  initInjectedForm(basicInfoContainer);
}

function qaKeywordsCallback(){
  const element = $('#questions-container');
  const url = element.data('url');
  $.ajax({
    url: url,
    method: 'GET',
    beforeSend: function (){
      blockElement(element);
    },
    success: function (response){
      element.html(response.responseView);
      $('.sub-navigation__item.selected').find('.badge').text(response.total_count);
      $('.tabs').foundation();
    },
    complete: function (){
      unblockElement(element);
    }
  });
}

function scrollToNewsfeedActions()
{
  let elem = $('#actions-container');
  scrollToElement(elem);
}

function triggerAuthorshipRequest(response){
  if (response.hasOwnProperty('add_by_doi')) {
    let doi = $('#publication_find_form_doi').val()
    let message =
        '<p>We did not find the publication with the number you entered, could you confirm it is written correct?</p>' +
        '<br/>' +
        '<div class="fs-16">' + doi + '</div>'

    confirmation_dialog(
        () => {$("#js-authorshiprequest-element").trigger("click")},
        'Confirm',
        message,
        'Confirm',
        'Edit'
    )
  } else if (response.hasOwnProperty('request_authorship')) {
    $("#js-authorshiprequest-element").trigger("click")
  }
}

function newPostCallback(response) {
  if (response.success && 'end' === response.state) {
    fillContent($('#actions-container'))
  }
}

function updateTotalInProfileHeaderMenu(response) {
  if (undefined !== response.total && undefined !== response.name) {
    let total = response.total
    let name = response.name
    let navItem = $('#nav-item-'+ name.toLowerCase() +' .profile-nav__item__link')
    if (total > 0) {
      navItem.html(name + ' <span class="sp-brackets">(' + total + ')</span>')
    } else {
      navItem.html(name)
    }
  }
}

function showOrHidePostAddBtn() {
  if ($('.feed-item--post').length) {
    $('#post-add-btn-container').removeClass('hide')
  } else {
    $('#post-add-btn-container').addClass('hide')
  }
}

function showConferenceAddBtn() {
  let conferenceListChild = $('.js_generic_list_childs');
  if(conferenceListChild.length) {
    $('#conference-add-btn-container').removeClass('hide');
  } else {
    $('#conference-add-btn-container').addClass('hide');
  }
}

function hideConferenceAddBtn() {
  let conferenceListChild = $('.js_generic_list_childs');
  if(conferenceListChild.length == 1) {
    $('#conference-add-btn-container').addClass('hide');
  } else {
    $('#conference-add-btn-container').removeClass('hide');
  }
}

function enableOrDisableFrequency(response) {
  $('#notification-type-'+response.objectId).find('.js_frequency_select').prop("disabled", !response.switch)
}

function keywordAdded() {
  $('.js-keyword-input-form').hide()
  $('.js-tag-keyword-add').show()
}

function callbackForScifeedDelete() {
  if (1 === $('#js_scifeed_container').children().length) {
    $('.selected .js-push-ajax').trigger('click');
  }
}

function postCommentCallback(data) {
  if (data.success && 'end' === data.state && undefined !== data.comments_count) {
    $('.js-comment-btn .js-comment-btn-text').html(data.comments_count);
  }
}

function hideUnclaimedTag() {
  $('.profile-header-img-unclaimed-tag').hide();
}

function showUserInfo(response) {
  if (response.show_user_info) {
    $('#profile-info-container').removeClass('hide');
    $('.doughnut-chart__canvas:visible').each(DoughnutChart.publishDoughnutChart);
  }
}

function clickNewDiscussionBtn() {
  $('.js-auto-trigger-click').trigger('click');
}

function setConfirmContent() {
  let result = '';
  $('#claim-profile-forms-wrapper .js-authorship-item').each(function (index, element){
    let item = $(element);
    if (item.find('.js-claim-profile-checkbox').prop("checked")) {
      let title = item.find('.js-claim-profile-checkbox').parent().text();
      let authorName = item.find('.js-authorship-author option:selected').html();
      let authorPosition = '';
      if (authorName === 'New') {
        authorPosition = item.find('.js-authorship-position option:selected').html();
      }
      result = result + `<div class="authorship__item">
                            <label class="publication-title">${title}</label>
                            <div class="author-id">${authorName}</div>
                            ${authorPosition ? `<div class="author-position">${authorPosition}</div>` : ''}
                        </div>`;
    }
  });
  $("#panel3").html(result);
}

function validateProfileClaimForm() {
  let result = true;
  $('div span.v2.help-text--error').remove();
  $('#claim-profile-forms-wrapper .js-form-step-content.is-active .js-authorship-item').each(function (index, element) {
    let item = $(element);
    if (item.find('.js-claim-profile-checkbox').prop("checked")) {
      let authorSelect = item.find('.js-authorship-author');
      let authorPosition = item.find('.js-authorship-position');
      if (authorSelect.val() == '') {
        result = false;
        authorSelect.parent('div').append('<span class="v2 help-text--error">This field should not be blank</span>');
      } else if (authorSelect.val() == '0' && authorPosition.val() == '') {
        result = false
        authorPosition.parent('div').append('<span class="v2 help-text--error">This field should not be blank</span>');
      }
    }
  });

  return result;
}

function pinToTopCallback() {
  if ($('.js-pin-to-top-container').length) {
    fillContent($('.js-pin-to-top-container'));
  }
  if ($('#newsfeed_wrap').length) {
    infiniteScroll.initialValue();
    infiniteScroll.initialize();
  }
}

function updateMessageSettingInSidebar(val) {
  $('#message-settings-receive-from input:radio[value=' + val + ']').prop('checked', true)
}

function changeBlockStatus(val) {
  let messageDialog = $('#message-dialog');
  if (messageDialog.data('topic') == val.topic) {
    messageDialog.find('.js-conversation-block-status').toggleClass('blocked');
  }
}

function closeMessageDialog(res) {
  let messageDialog = $('#message-dialog');
  if (messageDialog.data('topic') == res.topic) {
    messageDialog.find('.js-message-dialog-close').trigger('click');
  }
}

function closeDialogEventSource() {
  if (typeof dialogEventSource !== 'undefined') {
    dialogEventSource.close();
  }
}

function clearMessageUnreadCount() {
  $('#unread-message-count-wrapper').html('');
}

function refreshPrimaryEmailBox(res){
  $('#basic_info_primary_email').find('input').val(res.primaryEmail);
}

function informUserEmailSent(res) {
  if (res.modalResponseView) {
    openFoundationMessage(res.modalResponseView, 'new-modal-confirm', '');
  }
}

function unlinkOrcidCallback(res) {
  if (res.success && $('#basic_info_wrap').length) {
    fillContent($('#basic_info_wrap'));
  }
}

function genericMutationObserver() {
  let targetNodes = $('.js_generic_list_of_boxes');
  targetNodes.each(function(index, targetNode) {
    let observer = new MutationObserver(function(mutationsList, observer) {
      $(mutationsList).each(function(index, mutation) {
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          if ($(targetNode).html().trim() == '' || $(targetNode).find('.js_generic_list_childs').length == 0) {
            $(targetNode).find('.js_generic-empty-message-box').show();
            $(targetNode).prev('.js_generic-empty-message-box').show();
            if ($(targetNode).is('table')) {
              $(targetNode).hide();
            }
          } else {
            $(targetNode).find('.js_generic-empty-message-box').hide();
            $(targetNode).prev('.js_generic-empty-message-box').hide();
            if ($(targetNode).is('table')) {
              $(targetNode).show();
            }
          }
        }
      });
    });

    observer.observe(targetNode, { childList: true, subtree: true });
  });
}

function startCountdown(duration) {
  let timer = !isNaN(duration) ? duration : 60000;
  const resendButton = $('.js-resend-button');
  resendButton.prop('disabled', true);
  resendButton.text(`Resend（${Math.floor(timer / 1000)}s）`);

  const interval = setInterval(function () {
    timer -= 1000;
    resendButton.text(`Resend（${Math.floor(timer / 1000)}s）`);

    if (timer <= 0) {
      clearInterval(interval);
      resendButton.prop('disabled', false);
      resendButton.text('Resend');
    }
  }, 1000);
}

function deletePendingPublicationsCallback(response) {
    let totalPendingPublications = parseInt($('.publication-sub-menu .pending-publications').text());
    if (totalPendingPublications > 1) {
        $('.publication-sub-menu .pending-publications').text(totalPendingPublications - 1);
    } else {
        $('.publication-sub-menu .pending-publications').remove();
    }

    let totalPublications = parseInt($('#nav-item-publications .sp-brackets').text().match(/\d+/g));
    if (totalPublications > 1) {
        $('#nav-item-publications .sp-brackets').text(`(${totalPublications - 1})`);
    } else {
        $('#nav-item-publications .sp-brackets').remove();
    }
}
